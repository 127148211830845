import React from 'react'
import { graphql } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import trustedTrader from '../../static/images/trusted-trader.jpg'

// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, body }) => (
  <main className="Home">
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="gatsby-gdpr-google-analytics"
    >
      This website uses cookies to enhance the user experience.{' '}
    </CookieConsent>
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />

    <section className="section">
      <div className="container">
        <Content source={body} />
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.derbyshire.gov.uk/applications/trusted-trader/profile.asp?tid=60475"
          >
            <img style={{ width: 160, height: 116 }} src={trustedTrader} />
          </a>
        </div> */}
      </div>
    </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`
